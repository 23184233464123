import React from "react"
import "../components/layout.css"
import { useLocation } from "@reach/router"

import HeaderAffiliateSubmitted from "../components/HeaderComponents/HeaderAffiliateSubmitted"
import Footer from "../components/FooterComponents/Footer"

import { MainPageJson, PathJson } from "../components/location"
import Seo from "../components/SEOComponents/Seo"

function AffiliateForm() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const isBusiness = location.pathname.includes("/business/success")

  const seoDecider = isBusiness ? "business-success" : "affiliate-submitted"
  return (
    <div className="maxwidth">
      <Seo title={seoDecider} />
      <HeaderAffiliateSubmitted
        props={MainPageContent.Header}
        propsLink={PathContent}
        isEnglish={isEnglish}
        isBusiness={isBusiness}
      />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default AffiliateForm
