import React from "react"
import { Link } from "gatsby"
import { useStateIfMounted } from "use-state-if-mounted"
import { Container, Row, Col } from "react-bootstrap"
import { useLocation } from "@reach/router"
import {
  AffiliateFormHeader,
  SalutationAffiliate,
  AffiliateDot,
  AffiliateFormCol,
  AffiliateSubmittedDiv,
  AffiliateSubmittedButton,
} from "./HeaderStyle"
import bgAffiliate from "../../images/bg-affiliate-header.jpg"
import bgAffiliateRes from "../../images/bg-affiliate-header-mobile.jpg"
import CheckIcon from "../../images/icon-affiliate-submitted.svg"
import { NavigationBar } from "../NavigationBar/parent"

function HeaderAffiliateSubmitted({ props, propsLink, isEnglish, isBusiness }) {
  const location = useLocation()
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = status => {
    setStatusNav(status)
  }

  const businessText = isEnglish ? "Business Partner" : "Mitra Bisnis"
  const affiliateText = isEnglish ? "Affiliate Partner" : "Mitra Afiliasi"
  const titleDecider = isBusiness ? businessText : affiliateText

  return (
    <AffiliateFormHeader
      background={bgAffiliate}
      bgres={bgAffiliateRes}
      style={{ paddingTop: 120 }}
    >
      <NavigationBar />
      <Container className="flex-grow-1 mt-5">
        <SalutationAffiliate>
          <h2>{titleDecider}</h2>
        </SalutationAffiliate>
        <Row className="mt-4">
          <AffiliateFormCol md={6}>
            <p>{isEnglish ? "Thank You!" : "Terima Kasih!"}</p>
            <AffiliateSubmittedDiv>
              <img src={CheckIcon} alt="submitted" />
              <p>
                {isEnglish
                  ? "Your submission will be checked by our team. And we will send a response to your email immediately."
                  : "Kiriman Anda akan diperiksa oleh tim kami. Dan kami akan segera mengirimkan tanggapan ke email Anda."}
              </p>
            </AffiliateSubmittedDiv>
            <AffiliateSubmittedButton>
              <Link to={isEnglish ? "/en" : "/"}>
                <a>{isEnglish ? "Back to Home" : "Kembali ke beranda"}</a>
              </Link>
            </AffiliateSubmittedButton>
          </AffiliateFormCol>
        </Row>
      </Container>
    </AffiliateFormHeader>
  )
}

export default HeaderAffiliateSubmitted
